.dt-orchestra-content {
  min-height: 100vh!important;
  padding-bottom: 24px;
}

.dt-orchestra-description {
  color: var(--ion-color-medium);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 20px;
}

.dt-orchestra-footer-description {
  color: var(--ion-color-medium);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 20px;
}

.dt-recording-group-title {
  color: var(--ion-color-dark);
  font-family: 'wotfardmedium';
  font-weight: 400!important;
  font-size: 20px!important;
  line-height: 24px!important;
  padding-right: 20px;
  padding-bottom: 8px;
}
.md .dt-recording-group-title {
  margin-top: 29px;
}

.dt-recording-group-description {
  color: var(--ion-color-medium);
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 16px;
  padding-right: 20px;
}

.dt-empty-results-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 96px;
  color: var(--ion-color-medium);
  text-align: center;
}
.dt-empty-results-title {
  color: var(--ion-color-dark);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  padding-bottom: 8px;
}
