#orchestra-page {

  ion-toolbar {
    --border-style: none!important;
    --padding-start: 0;
    --padding-end: 0;
  }

  ion-toolbar#active-filters-toolbar {
    border-bottom: 1px solid var(--ion-color-light);
  }

  .dt-toolbar-title {
    font-family: 'wotfardmedium';
    font-size: 18px;
    font-weight: 400;
  }

  .searchbar-search-icon.sc-ion-searchbar-ios {
    left: 8px;
  }
  .searchbar-input.sc-ion-searchbar-ios {
    padding-left: 36px;
  }

  /*
   * Design for active filters
   *
   * We are using debounced "scroll" event listener to apply appropriate class name to toolbars to be able
   * to manipulate toolbar styling.
   */

  /* Hide active-filters toolbar by default */
  ion-content ion-toolbar.active-filters {
    display: none;
  }

  /* Show active-filters toolbar when user scrolls the content and make it sticky */
  ion-content ion-toolbar.active-filters.visible {
    display: block;
    top: 0;
    position: sticky;
    z-index: 20;
  }

  /* -- */


  /*
   * Enable content of the targeted container to be scrolled horizontally with invisible scrollbar
   */
  .scroll-horizontally {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    //-ms-overflow-style: none;  /* Hide scrollbar in IE and Edge => Ionic is not using IE */
    //scrollbar-width: none;  /* Hide scrollbar in Firefox => Ionic is not using Firefox */
  }
  .scroll-horizontally::-webkit-scrollbar { /* Hide scrollbar in Chrome, Safari and Opera */
    display: none;
  }

  /* -- */

  .dt-active-filters-buttons {
    padding-left: 12px;
    padding-right: 12px;
  }

  .dt-orchestra-image-container {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .dt-orchestra-image {
    max-width: 240px;
  }

  .dt-searchbar {
    --border-radius: 18px;
    --color: var(--ion-color-dark)!important;
    --placeholder-color: var(--ion-color-medium)!important;
    --background: var(--ion-color-light);
  }
  .dt-searchbar input {
    font-size: 16px!important;
  }

  .dt-tag-filters-toolbar {
    margin-left: 0px;
  }
}
