#recording-modal { /** Only recording modal background should be slightly of lighter color */
  body.dark ion-modal {
    --ion-background-color: #161616;
    --ion-toolbar-background: #161616;
    /*--ion-toolbar-border-color: var(--ion-color-step-250);*/
  }
}

ion-toolbar {
  --background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
}
ion-toolbar:last-of-type {
  --border-width: 0!important;
}

.dt-recording-modal-toolbar {
  margin-left: 0!important;
  padding-left: 0!important;
}

.dt-recording-modal-content {
  padding: 24px;
  padding-top: 16px;
}

.dt-recording-modal-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--ion-color-dark);
  margin-bottom: 4px;
}

.dt-recording-modal-favourite-icon {
  float:right;
  font-size: 24px;
  color: var(--ion-color-primary);
}

.dt-recording-modal-orchestra-singer {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  color: var(--ion-color-dark);
}
.dt-recording-modal-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-right: 16px;
  color: var(--ion-color-medium);
}
.dt-recording-modal-value {
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  line-height: 20px;
  color: var(--ion-color-dark);
}
.dt-recording-modal-separator {
  border-top: 1px solid #0000001A;
  border-bottom: none;
  margin-top: 16px;
  margin-bottom: 16px;
}
body.dark .dt-recording-modal-separator {
  border-top: 1px solid #FFFFFF1A;
}
.dt-recording-modal-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}