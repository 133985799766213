:root {
  font-size: 14px;
}

ion-searchbar {
  --box-shadow: none;
  --background: var(--ion-color-light) !important;
  --box-shadow: none!important;
}

/* Remove toolbar border in MD */
.header-md::after {
  background: none;
}
.footer-md::before {
  background: none;
}

/*
 * Global override for collapsable large title so that it keeps same properties during transition
 *   (https://ionicframework.com/docs/api/title#styling-collapsible-large-titles-2)
 */
ion-title.title-large {
  font-family: 'wotfardmedium';
  font-weight: 400;
  font-size: 32px;
  padding-left: 20px;
}

ion-list-header {
  padding-left: 16px;
}

ion-item {
  --padding-start: 16px;
}

ion-item > ion-icon {
  font-size: 40px;
  margin-right: 12px;
}

ion-item > ion-img {
  margin-right: 12px;
}

ion-button {
  --border-radius: 100px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  text-transform: none;
  letter-spacing: -0.48px;
  height: auto;
}

ion-back-button.ios.back-button-has-icon-only {
  padding-right: 24px;
}

/* Toast dark theme */
body.dark ion-toast {
  --background: #222428;
}
body.dark ion-toast {
  --color: #F0F0F0;
}

.button-native {
  text-transform: none!important;
}

/** Global common custom classes */
.dt-button-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.dt-empty-results-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 96px;
  padding-left: 16px;
  padding-right: 20px;
  color: var(--ion-color-medium);
  text-align: center;
}
.dt-empty-results-title {
  color: var(--ion-color-dark);
  font-family: 'wotfardmedium';
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 8px;
}
