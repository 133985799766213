/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: 'wotfardregular';
  src: url('../../public/assets/fonts/Wotfard/wotfard-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'wotfardmedium';
  src: url('../../public/assets/fonts/Wotfard/wotfard-medium-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #EE19E5;
  --ion-color-primary-rgb: 238,25,229;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #d116ca;
  --ion-color-primary-tint: #f030e8;

  --ion-color-secondary: #BA27B4;
  --ion-color-secondary-rgb: 186,39,180;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #a4229e;
  --ion-color-secondary-tint: #c13dbc;

  --ion-color-tertiary: #5260FF;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2FD36F;
  --ion-color-success-rgb: 47,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29ba62;
  --ion-color-success-tint: #44d77d;

  --ion-color-warning: #FDC40C;
  --ion-color-warning-rgb: 253,196,12;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #dfac0b;
  --ion-color-warning-tint: #fdca24;

  --ion-color-danger: #EC445A;
  --ion-color-danger-rgb: 236,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d03c4f;
  --ion-color-danger-tint: #ee576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949C;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #F4F5F8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0,0,0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255,255,255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /* Set the font family of the entire app */
  --ion-font-family: wotfardregular, sans-serif;

  /** Toolbar **/
  --ion-toolbar-background: #ffffff;
}

/*@media (prefers-color-scheme: dark) {*/
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body.dark {
    --ion-color-primary: #BA27B4;
    --ion-color-primary-rgb: 186,39,180;
    --ion-color-primary-contrast: #f0f0f0;
    --ion-color-primary-contrast-rgb: 240,240,240;
    --ion-color-primary-shade: #a4229e;
    --ion-color-primary-tint: #c13dbc;

    --ion-color-secondary: #BA27B4;
    --ion-color-secondary-rgb: 186,39,180;
    --ion-color-secondary-contrast: #f0f0f0;
    --ion-color-secondary-contrast-rgb: 240,240,240;
    --ion-color-secondary-shade: #a4229e;
    --ion-color-secondary-tint: #c13dbc;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #f0f0f0;
    --ion-color-tertiary-contrast-rgb: 240,240,240;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #f0f0f0;
    --ion-color-danger-contrast-rgb: 240,240,240;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #F4F5F8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #92949C;
    --ion-color-medium-rgb: 146,148,156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #f0f0f0;
    --ion-color-light-contrast-rgb: 240,240,240;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /* todo: add --ion-color-white and --ion-color-black varitions for dark theme */

    --ion-toolbar-background: #121212;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #f0f0f0;
    --ion-text-color-rgb: 240,240,240;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #121212;

    --ion-card-background: #121212;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #f0f0f0;
    --ion-text-color-rgb: 240,240,240;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #121212;

    --ion-toolbar-background: #121212;

    --ion-tab-bar-background: #121212;

    --ion-card-background: #121212;
  }
/*}*/
