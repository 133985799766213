.skeleton-image-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.skeleton-image {
  width: 160px;
  height: 160px;
}
.skeleton-search-filter-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.skeleton-search-filter {
  width: calc(100vw - 40px);
  height: 30px;
}
.skeleton-tag-filter-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 20px;
}
.skeleton-tag-filter {
  width: 80px;
  margin-right: 10px;
  height: 30px;
}
.skeleton-title-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 40px;
}
.skeleton-title {
  width: calc(80vw - 40px);
  height: 30px;
}
.skeleton-record-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 20px;
}
.skeleton-record {
  width: calc(100vw - 40px);
  height: 80px;
  margin-bottom: 20px;
}
