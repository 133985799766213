#orchestras-page {
  ion-toolbar {
    --border-style: none!important;
  }

  .dt-toolbar-title {
    font-family: 'wotfardmedium';
    font-size: 18px;
    font-weight: 400;
  }
  .dt-content-header {
    padding-bottom: 10px;
  }

  .dt-footer {
    margin-top: 24px;
    margin-bottom: 48px;
  }
  .dt-footer p {
    color: var(--ion-color-medium);
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .dt-footer a {
    color: var(--ion-color-dark);
    text-decoration: none;
  }

  .dt-footer-options-container {
    background-color: var(--ion-color-light);
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    border-radius: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  body.dark .dt-footer-options-container {
    background-color: var(--ion-color-dark);
  }
  .dt-footer-options-container {
    background-color: var(--ion-color-light);
  }
  body.dark .dt-footer-options-container {
    background-color: var(--ion-color-light);
  }
  .dt-footer-options-container p {
    color: var(--ion-color-medium);
  }
  .dt-footer-options-container a {
    color: var(--ion-color-medium);
  }
  .dt-footer-options-container a.active {
    color: var(--ion-color-dark);
  }
  body.dark .dt-footer-options-container a.active {
    color: var(--ion-color-light);
  }


  /* ?? */
  ion-footer {
    ion-toolbar {
      --background: none;
      --border-width: 0;
    }
  }

  //ion-content {
  //  display: flex;
  //}
  //
  //#content {
  //  min-height: 100%;
  //  display: flex;
  //  flex-direction: column;
  //  flex-grow: 1;
  //}
  //
  //#list {
  //  flex: 1 0 auto;
  //}
  //
  //#footer {
  //  flex-shrink: 0;
  //}
}
