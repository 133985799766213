#tutorial-page {
  ion-toolbar {
    --background: transparent;
    --border-color: transparent;
    padding-left:22px;
    padding-right:22px;
    padding-bottom: 44px;
  }

  .swiper-slide {
    display: block;
    padding-top: 64px;
  }

  .slide-title {
    margin-top: 2.8rem;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: var(--ion-color-dark);
  }

  .slide-image {
    max-height: 50%;
    max-width: 60%;
    margin: 36px 0;
    pointer-events: none;
  }

  p {
    padding: 0 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--ion-color-medium);
  }

  // Move pagination to top
  .swiper-pagination {
    top: 10px;
    bottom: unset;
  }
}
