.dt-recording-list-item-label {
  margin-top: 12px;
  margin-bottom: 12px;
}

.dt-recording-list-item-first-row {
  display: flex;
  height: 22px;
}

.dt-recording-list-item-recording-title {
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px!important;
  line-height: 16px;
  color: var(--ion-color-dark);
}

.dt-recording-list-item-orchestra-name {
  font-weight: 400;
  font-size: 14px!important;
  line-height: 20px;
  color: var(--ion-color-medium);
}
.dt-recording-list-item-year {
  font-weight: 400;
  font-size: 14px!important;
  line-height: 20px;
  color: var(--ion-color-dark);
}
.dt-recording-item-icon-favourites {
  height: 24px;
  padding-left: 6px;
  padding-bottom: 4px;
}
.dt-recording-item-icon-favourites > ion-icon {
  height: 20px;
}
ion-item-options.item-options-ios {
  border: none;
}
ion-item-options.item-options-md {
  border: none!important;
}
