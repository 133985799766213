ion-chip {
  margin-top: 6px;
  margin-bottom: 6px;
}

ion-chip.ion-color-primary {
  color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary);
}

ion-chip.ion-color-light {
  color: var(--ion-color-dark);
  background-color: var(--ion-color-light);
}
ion-chip.ion-color-light .dt-icon-favourite {
  color: var(--ion-color-primary);
}