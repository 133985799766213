#search-modal {
  ion-toolbar {
    --background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
  }
  ion-toolbar:nth-last-child(1) {
    box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  }

  .dt-toolbar-title {
    font-family: 'wotfardmedium';
    font-size: 18px;
    font-weight: 400;
  }

  .dt-searchbar {
    --border-radius: 18px;
    --color: var(--ion-color-dark)!important;
    --placeholder-color: var(--ion-color-medium)!important;
  }
  .dt-searchbar input {
    font-size: 16px!important;
  }
  .searchbar-search-icon.sc-ion-searchbar-ios {
    left: 8px;
  }
  .searchbar-input.sc-ion-searchbar-ios {
    padding-left: 36px;
  }

  .dt-search-list {
    margin-bottom: 12px;
  }

  .dt-search-list-title {
    font-family: 'wotfardmedium';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--ion-color-dark);
    padding-bottom: 16px;
  }
  .md .dt-search-list-title {
    padding-bottom: 0;
  }

  .dt-search-list-item-title {
    font-weight: 400;
    font-size: 16px!important;
    line-height: 20px;
    color: var(--ion-color-dark);
  }

  .dt-recording-list-show-more {
    display: inline-block;
    margin: auto;
    margin-bottom: 0!important;
    font-family: 'wotfardmedium';
    font-size: 16px!important;
    line-height: 42px;
    color: var(--ion-color-dark);
  }

  .dt-orchestra-list-item {
    padding-top: 11px;
    padding-bottom: 12px;
    font-size: 16px!important;
    font-weight: 400;
  }
}
